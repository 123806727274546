<!--
 * @Author: mjzhu
 * @describe: 
 * @Date: 2023-02-06 14:55:25
 * @LastEditTime: 2024-01-26 15:09:24
 * @FilePath: \awx-ui\src\pages\newHostManage\leftTree.vue
-->
<template>
  <div class="left-tree" :style="{ height: 'calc(100% - 0px)' }">
    <div class="sider-header-button">
      <!-- <a-input style="width: calc(100% - 39px)" v-model="searchValue" @pressEnter="changeSearchValue" placeholder="输入主机分组名称">
        <svg-icon slot="suffix" icon-class="realtime-search" />
      </a-input> -->
      <a-select style="width: calc(100% - 39px); margin-left: 24px;" v-model="cluster_id" @change="convertTreeData">
        <a-select-option v-for="(item, index) in masterOptions" :disabled="item.status !== 'online'" :key="item.id" :value="item.id">
          <span class="flex-center-container">
             <svg-icon class="mgr8" :icon-class="item.status === 'online' ? 'task-successful' : 'task-init'" :style="{color: item.status === 'online' ? '#5CB863' : '#ED4F50'}"></svg-icon>
             <span>{{ item.name }}</span>
          </span>
        </a-select-option>
      </a-select>
    </div>
    <a-spin :spinning="treeSpain" class="left-tree-container">
      <div :class="'sysDevStyle'" style="overflow-y: auto; padding: 0 10px">
        <a-tree :treeData="treeData" class="a-tree-local-layout" @expand="onExpand" :expandedKeys="expandedKeys" :autoExpandParent="autoExpandParent" :selectedKeys="selectedKeys" defaultExpandAll @select="onSelect">
          <template slot="host" slot-scope="item">
            <div :class="['local-tree-custom', item.isLeaf && 'pdl15']">
              <template v-if="item.isLeaf">
                <span style="margin: 0 4px 1px">
                  <svg-icon :icon-class="item.isLeaf ? 'ope-group': 'ope-group'" style="font-size: 16px"></svg-icon>
                </span>
              </template>
              <span class="local-tree-content" :title="item.title">
                <span v-if="item.title.indexOf(searchValue) == -1">{{ item.title }}</span>
                <span v-else :title="item.title">
                  {{
                  item.title.substr(
                  0,
                  item.title.indexOf(searchValue)
                  )
                  }}
                  <span style="color: #f50">{{ searchValue }}</span>
                  {{
                  item.title.substr(
                  item.title.indexOf(searchValue) +
                  searchValue.length
                  )
                  }}
                </span>
              </span>
              <span class="tree-more hide-point" style="width: 10px; text-align: right">
                <div @click.stop>
                  <a-popover placement="rightTop" class="popover-index" overlayClassName="popover-index" :content="() => getMoreMenu(item)">
                    <a-icon type="more" @click="showPopover(item)" />
                  </a-popover>
                </div>
              </span>
            </div>
          </template>
          <template slot="target" slot-scope="item">
            <div :class="['local-tree-custom', item.isLeaf && 'pdl15']">
              <template v-if="item.isLeaf">
                <span style="margin: 0 4px">
                  <svg-icon :icon-class="item.isLeaf ? 'ope-target-group' : 'ope-target-group'" style="color: #919ab2;font-size: 16px"></svg-icon>
                </span>
              </template>
              <span class="local-tree-content" :title="item.title">
                <span v-if="item.title.indexOf(searchValue) == -1">{{ item.title }}</span>
                <span v-else :title="item.title">
                  {{
                  item.title.substr(
                  0,
                  item.title.indexOf(searchValue)
                  )
                  }}
                  <span style="color: #f50">{{ searchValue }}</span>
                  {{
                  item.title.substr(
                  item.title.indexOf(searchValue) +
                  searchValue.length
                  )
                  }}
                </span>
              </span>
              <span class="tree-more hide-point" style="width: 10px; text-align: right">
                <div @click.stop>
                  <a-popover placement="rightTop" class="popover-index" overlayClassName="popover-index" :content="() => getMoreMenu(item)">
                    <a-icon type="more" @click="showPopover(item)" />
                  </a-popover>
                </div>
              </span>
            </div>
          </template>
        </a-tree>
      </div>
    </a-spin>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AddHostGroup from "./components/host/addGroup.vue";
import DeleteHostGroup from "./components/host/deleteHost.vue";

import AddTargetGroup from "./components/target/addGroup.vue";
import DeleteTargetGroup from "./components/target/deleteHost.vue";
export default {
  data() {
    return {
      searchValue: "",
      autoExpandParent: true,
      selectedKeys: [],
      expandedKeys: ["主机分组"],
      treefilterData: [],
      treeData: [],
      treeSpain: false,
      selectType: 'host',
      groupId: 0,
      cluster_id: undefined,
      topButtonTree: [{ name: "新建主机组", key: "add" }],
    };
  },
  mounted() {
    this.initClusterid()
  },
  computed: {
    masterOptions () {
      const options = localStorage.getItem('masterOptions')
      const masterOptions = (options && options.length) ? JSON.parse(options) : []
      return masterOptions
    }
  },
  methods: {
    initClusterid () {
      if (!this.masterOptions) this.cluster_id = undefined
      const onLlineCluster = this.masterOptions.filter(item => item.status === 'online')
      if (onLlineCluster.length > 0) {
        const DefaultMaster = onLlineCluster.find(item => (item.is_default))
        this.cluster_id = DefaultMaster?.id || onLlineCluster[0]?.id || undefined
      }
      this.refreshPage();
    },
    onExpand(expandedKeys, { node }) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    changeSearchValue() {
      // this.getHostGroups();
      this.convertTreeData();
    },
    disTreeData(data, _expandedKeys) {
      if (data && data.length > 0) {
        data.forEach((item) => {
          if (_expandedKeys && _expandedKeys.indexOf(item.key) < 0) {
            item["style"] = { display: "none" };
          } else {
            item["style"] = { display: "block" };
          }
          if (item.children && item.children.length != 0) {
            this.disTreeData(item.children, _expandedKeys);
          }
        });
      }
    },
    convertTreeData() {
      this.treeData = [
        {
          name: "主机分组",
          isLeaf: false,
          key: "主机分组",
          title: "主机分组",
          type: 'host',
          children: [],
          scopedSlots: { title: "host" },
        }
      ];
      this.labelsList = [];
      if (!this.cluster_id) return false
      this.treeSpain = true;
      const params = {
        keyword: this.searchValue,
        page_size: 1000,
        cluster_id: this.cluster_id
      };
      this.$axiosGet(global.API.getHostGroups, params).then((res) => {
        this.treeSpain = false;
        if ([200, 201, 204, 202].includes(res.status)) {
          if (res.data.results && res.data.results.length > 0) {
            this.treeData[0].children = []
            res.data.results.map((item) => {
              this.treeData[0].children.push({
                name: item.name,
                isLeaf: true,
                key: "group" + item.id,
                pid: 0,
                type: 'host',
                svgIcon: 'ope-group',
                title: item.name,
                children: [],
                ...item,
                scopedSlots: { title: "host" },
              });
              this.labelsList.push(item.name);
            });
          }
        }
      });
      this.expandedKeys = ["主机分组"];
    },
    showPopover(item) {
      this.popoverVisible = true;
    },
    refreshPage () {
     this.convertTreeData()
     setTimeout(() => {
       this.selectType === 'host' ? this.$EventBus.$emit('getHostList', this.cluster_id) : this.$EventBus.$emit('getTargetList')
     }, 1000)
    },
    showPopDetail(keys, node) {
     /**
       * 操作集
       */
      this.popoverVisible = false;
      const self = this;
      let width = keys === "edit" || keys === "build" ? 560 : 400;
      let title = keys === "edit" ? "编辑主机组" : "新建主机组";
      let content =
        keys === "edit" || keys === "build" ? (
          node.dataRef.type === 'host' ? <AddHostGroup detail={node} clusterId= {this.cluster_id} callBack={() => self.refreshPage()} /> : <AddTargetGroup clusterId= {this.cluster_id} detail={node} callBack={() => self.refreshPage()} />
        ) : (
          node.dataRef.type === 'host' ? <DeleteHostGroup
            sysTypeTxt="主机组"
            clusterId= {this.cluster_id}
            detail={node}
            type="group"
            refreshList={() => self.convertTreeData()}
          />: <DeleteTargetGroup
            sysTypeTxt="目标组"
            detail={node}
            clusterId= {this.cluster_id}
            type="group"
            refreshList={() => self.convertTreeData()}
          />
        );
      this.$confirm({
        width: width,
        title:
          keys === "edit" || keys === "build"
            ? title
            : () => {
                return (
                  <div>
                    <a-icon
                      type="question-circle"
                      style="color:#2F7FD1 !important;margin-right:10px"
                    />
                    提示
                  </div>
                );
              },
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    destroyAll() {
      this.$destroyAll();
    },
    getMoreMenu(props) {
      const { isLeaf, expanded, type, editStatus, name, dataRef, pNodeId } =
        props;
      let arr = [];
      if (['主机分组', '目标分组'].includes(name)) {
        const nodeName = name === '主机分组' ? '新建主机组' : '新建目标组'
        arr = [{ name: nodeName, key: "build" }];
      }
      if (!['主机分组', '目标分组'].includes(name)) {
        arr = [
          { name: "编辑", key: "edit" },
          { name: "删除", key: "delete" },
        ];
      }
      return arr.map((item, index) => {
        return (
          <div key={index}>
            <a-button
              class="more-menu-btn"
              style="border-width:0px;min-width:100px;"
              onClick={() => this.showPopDetail(item.key, props)}
            >
              {item.name}
            </a-button>
          </div>
        );
      });
    },
    onSelect(keys, { node }) {
      // if (['主机分组', '目标分组'].includes(node.dataRef.name)) return false
      // if (node.isLeaf) return this.$message.warning('页面待开发')
      // 点击打开的父节点的时候将父节点选中收起来
      if (node.expanded && !node.isLeaf) {
        this.expandedKeys = this.expandedKeys.filter(
          (item) => item !== node.eventKey
        );
      }
      if (!keys.length) return false;
      this.selectedKeys = keys;
      this.selectType = node.dataRef.type
      this.groupId = node.dataRef.id;
      this.$EventBus.$emit('getTreeSelectData', {groupId: this.groupId, selectType: this.selectType})
    },
    clickPopCommon(item) {
      if (item.key == "search") {
        this.changeSearchValue();
      }
      if (item.key == "sync") {
        this.getHostGroups();
      }
    },
  },
};
</script>
<style lang="less" scoped>
// 右边的气泡
.popover-index {
  .more-menu-btn:hover {
    background: #f4f5f7;
    color: #0264c8;
  }
  .ant-popover-inner-content {
    padding: 12px 0;
  }
  /deep/ .ant-popover-arrow {
    top: 20px !important;
    left: 20px !important;
  }
  .ant-popover-inner-content .ant-btn:hover,
  .ant-popover-inner-content .ant-btn:focus {
    background-color: #f4f5f7;
  }
  .ant-menu-vertical .ant-menu-item:not(:last-child),
  .ant-menu-vertical-left .ant-menu-item:not(:last-child),
  .ant-menu-vertical-right .ant-menu-item:not(:last-child),
  .ant-menu-inline .ant-menu-item:not(:last-child) {
    margin-bottom: 4px;
    margin-top: 1px;
  }
  .ant-menu-inline-collapsed {
    width: 50px;
  }
  .ant-menu-item-selected {
    background: #2872e0 !important;
    color: #ffffff !important;
  }
}
.left-tree {
  // 搜索框
  .sider-header-button {
    padding: 20px 8px 5px 0;
    .ant-input-search {
      margin-left: 16px;
      margin-right: 16px;
      .ant-input-affix-wrapper .ant-input-suffix {
        right: 6px;
      }
    }
    .ant-input-affix-wrapper {
      margin-left: 12px;
      margin-right: 4px;
      margin-left: 24px;
    }
    .ant-input {
      border-radius: 2px;
      margin: 0;
      // background-color: #F2F4F7;
      // border-width:0px
      height: 30px;
      line-height: 30px;
      padding-left: 8px;
    }
    .sider-header-btn {
      box-shadow: none;
    }
  }
  .left-tree-container {
    max-height: calc(100% - 40px);
    overflow-y: auto;
    margin-left: 14px;
    margin-right: 12px;
    .sysDevStyle {
      height: calc(100% - 35px) !important;
      overflow-y: scroll;
      .a-tree-local-layout {
        // padding: 0 14px 0 0px;

        .ant-tree li ul li {
          padding: 5px 0 5px 16px;
          border-bottom: 1px solid #f6f7fb;
        }
        .ant-tree li ul:not(.ant-tree-child-tree-open) {
          padding: 5px 0 5px 16px;
        }
        .ant-tree-child-tree-open {
          padding: 0;
        }
        .ant-tree-treenode-selected:not(.ant-tree-treenode-switcher-open) {
          background: #cce1fe;
          border-radius: 4px;
          .local-tree-content {
            // font-size: 14px;
            color: #0264c8 !important;
            letter-spacing: 0;
            font-weight: 400;
          }
          .popover-index {
            color: #0181ff;
          }
        }
        .ant-tree-treenode-switcher-close {
          // background-color: #fff !important;
        }
        .ant-tree-node-content-wrapper {
          width: calc(100% -24px) !important;
          padding: 0 10px 0 0 !important;
          background: rgba(0, 0, 0, 0) !important;
          opacity: 1 !important;
          border-width: 0px;
          // vertical-align: middle;
          line-height: 24px;
        }
        .local-tree-custom {
          display: flex;
          align-items: center;
          .local-tree-content {
            padding: 0 5px;
            color: #000000a6;
            text-align: left;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .tree-more {
            color: #f2f4f7;
          }
        }
        .pdl15 {
          position: relative;
          left: -15px;
          .local-tree-content {
            width: calc(100%);
          }
          .tree-more {
            position: relative;
            right: -15px;
          }
        }
        .local-tree-custom:hover {
          .tree-more {
            color: #2281e1;
          }
        }
      }
    }
  }
}
</style>