<!--
 * @Description: 
 * @Date: 2022-12-14 16:13:57
 * @LastEditors: xiaopang
 * @LastEditTime: 2023-03-16 16:01:28
-->
<template>
  <HostList v-if="isHost" :hostGroupId="groupId" />
  <TargetList v-else :hostGroupId="groupId" />
</template>
<script>
import HostList from "./hostList.vue";
import TargetList from './targetList.vue'
export default {
  components: {
    HostList,
    TargetList
  },
  data() {
    return {
      isHost: true,
      groupId: 0,
    };
  },
  methods: {
    getTreeSelectData(obj) {
      this.groupId = obj.groupId;
      this.isHost = obj.selectType === 'host'
    },
  },
  mounted() {
    this.$EventBus.$on("getTreeSelectData", (obj) => {
      this.getTreeSelectData(obj);
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("getTreeSelectData");
  },
};
</script>
<style lang="less" scoped>
</style>