var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"padding":"10px 10px 10px 0"}},[_c('a-form',{staticClass:"p0-32-10-32 form-content-host",attrs:{"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"form":_vm.form}},[_c('a-form-item',{attrs:{"label":"命令类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'lang',
          { rules: [{ required: true, message: '命令类型不能为空!' }] },
          ]),expression:"[\n          'lang',\n          { rules: [{ required: true, message: '命令类型不能为空!' }] },\n          ]"}],attrs:{"placeholder":"请选择命令类型"}},_vm._l((_vm.tabsItem),function(item){return _c('a-select-option',{key:item.key,attrs:{"value":item.key}},[_vm._v(_vm._s(item.label))])}),1)],1),_c('a-form-item',{attrs:{"label":"名称"}},[_c('a-auto-complete',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'name',
            { rules: [{ required: true, message: '名称不能为空!' }] },
          ]),expression:"[\n            'name',\n            { rules: [{ required: true, message: '名称不能为空!' }] },\n          ]"}],attrs:{"data-source":_vm.labelsList,"placeholder":"输入或选择名称"},on:{"select":_vm.selectName,"change":_vm.onChange}})],1),_c('a-form-item',{attrs:{"label":"详细内容"}},[_c('editor',{ref:"formEditor",attrs:{"id":"editor","editorHeight":'200px',"propsCode":_vm.logData,"readOnly":false,"theme":"vs-dark","language":"language"},on:{"getEditorValue":_vm.getEditorValue}})],1)],1),_c('div',{staticClass:"ant-modal-confirm-btns-new",staticStyle:{"margin":"0"}},[_c('a-button',{staticStyle:{"margin-right":"10px"},attrs:{"type":"primary","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.handleSubmit.apply(null, arguments)}}},[_vm._v("确认")]),_c('a-button',{on:{"click":function($event){$event.stopPropagation();return _vm.formCancel.apply(null, arguments)}}},[_vm._v("取消")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }