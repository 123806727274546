<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2022-09-09 15:00:39
 * @FilePath: \awx-ui\src\pages\hostManage\components\addHost.vue
-->
<template>
  <div style="padding: 10px 10px 10px 0">
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol" :form="form" class="p0-32-10-32 form-content-host">
      <!-- <a-form-item label="添加方式">
        <a-radio-group v-decorator="['addType', { rules: [{ required: true, message: '添加方式不能为空!' }]}]" placeholder="请选择添加方式">
          <a-radio :value="1">单个添加</a-radio>
        </a-radio-group>
      </a-form-item> -->
      <a-form-item label="主机组">
        <a-select :show-search="true" :not-found-content="null" :filter-option="true" :allowClear="true" v-decorator="['groupId', { rules: [{ required: true, message: '主机组不能为空!' }]}]" placeholder="请选择或输入主机组" @search="handleSearch" @blur="handleBlur"  @change="handleChange">
          <a-select-option :value="item.id" v-for="(item,index) in hostGroupList" :key="index">{{item.name}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="IP">
        <a-input @change="changeIp" v-decorator="[
            'ip',
            { rules: [{ required: true, message: 'IP地址不能为空!' }] },
          ]" placeholder="请输入IP地址" />
      </a-form-item>
      <a-form-item label="主机名">
        <a-input :disabled="isDisabled" v-decorator="[
            'name',
            { rules: [{ required: true, message: '主机名不能为空!' }] },
          ]" placeholder="请输入主机名" />
      </a-form-item>
      <a-form-item label="操作系统">
        <a-select v-decorator="['osType', { rules: [{ required: true, message: '操作系统不能为空!' }]}]" placeholder="请选择操作系统">
          <a-select-option :value="item.value" v-for="(item,index) in osTypeList" :key="index">{{item.label}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="主机标签">
        <a-select mode="tags" v-decorator="['labels', { rules: [{ required: false, message: '主机标签不能为空!' }]}]" placeholder="请选择主机标签">
          <a-select-option :value="item" v-for="(item,index) in labelsList" :key="index">{{item}}</a-select-option>
        </a-select>
      </a-form-item>
       <a-form-item label="用户名">
        <a-input type="username" v-decorator="['username',{ rules: [{ required: true, message: '用户名不能为空!' }] }]" placeholder="请输入用户名" />
      </a-form-item>
      <a-form-item label="密码">
        <a-input v-if="!isShow" type="password" v-decorator="['password',{ rules: [{ required: !editFlag, message: '密码不能为空!' }] }]" placeholder="请输入密码" />
        <a-input v-else v-decorator="['password',{ rules: [{ required: !editFlag, message: '密码不能为空!' }] }]" placeholder="请输入密码" />
        <a-icon :type="!isShow ? 'eye-invisible':'eye'" @click.stop="isShow=!isShow" style="position: absolute;cursor: pointer;right: 10px;top: 1px;"/>
      </a-form-item>
      <a-form-item label="端口号">
        <a-input v-decorator="['port',{ rules: [{ required: true, message: '端口号不能为空!' }] }]" placeholder="请输入端口号，如：22" />
      </a-form-item>
      <a-form-item label="认证方式">
        <a-radio-group v-decorator="['authType', { rules: [{ required: false, message: '认证方式不能为空!' }]}]" :disabled="true" placeholder="请选择认证方式">
          <a-radio :value="1">密码</a-radio>
          <a-radio :value="2">密钥</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item v-for="(child, childIndex) in hostVals" style="margin-bottom: 0px" :key="childIndex" v-bind="childIndex === 0 ? labelCol : formItemLayoutWithOutLabel" :label="childIndex === 0  ? '主机变量' : ''">
        <a-row type="flex" style="position: relative">
          <a-col :span="12">
            <a-form-item style="width:97%">
              <a-input v-decorator="[
              `${child.randow+'arrayWithKey'+childIndex}`,
              {
              validateTrigger: ['change', 'blur'],
              initialValue: child.name,
              rules: [
                {
                  required: false,
                  whitespace: true,
                  message: `变量名不能为空!`,
                },
              ],
            }
            ]" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item style="width:97%">
              <a-input v-decorator="[
              `${child.randow+'arrayWithValue'+childIndex}`,
              {
              validateTrigger: ['change', 'blur'],
              initialValue: child.value,
              rules: [
                {
                  required: false,
                  whitespace: true,
                  message: `变量值不能为空!`,
                },
              ],
            }
            ]" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <span style="position: absolute; right: -16px" @click="() => reduceMultiple(childIndex)">
            <svg-icon v-if="hostVals.length > 1" icon-class="reduce-icon" class="reduce-icon" />
          </span>
        </a-row>
      </a-form-item>
      <a-form-item class="form-multiple-item" v-bind="formItemLayoutWithOutLabel" :label="''">
        <a-button type="dashed" @click="() => addMultiple()">
          <a-icon type="plus" />
        </a-button>
      </a-form-item>
      <a-form-item label="描述">
        <a-input v-decorator="['desc',{ rules: [{ required: false, message: '描述不能为空!' }] }]" type="textarea" placeholder="请输入描述" />
      </a-form-item>
      
    </a-form>
    <div class="ant-modal-confirm-btns-new" style="margin: 0">
      <a-button style="margin-right: 10px" type="primary" @click.stop="handleSubmit" :loading="loading">确认</a-button>
      <a-button @click.stop="formCancel">取消</a-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    hostGroupId: Number,
    callBack: Function,
  },
  data() {
    const self = this
    return {
      isDisabled:false,
      isShow:false,
      editFlag: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
      initFormFiledFlag: false,
      hostVals: [],
      form: this.$form.createForm(this, {
        onValuesChange: function (props, fileds) {
          if (self.initFormFiledFlag) {
            self.initFormFiledFlag = false
            return false
          }
          for (var key in fileds) {
            if(key.includes('arrayWithKey')) {
              self.syncFormdata(fileds, key, 'arrayWithKey');
            }
            if(key.includes('arrayWithValue')) {
              self.syncFormdata(fileds, key, 'arrayWithValue');
            }
          }
        },
      }),
      loading: false,
      hostGroupList: [],
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 19, offset: 5 },
        },
      },
      labelsList: [],
      osTypeList: [
        {
          label: "Linux",
          value: "Linux",
        },
        {
          label: "Windows",
          value: "Windows",
        },
      ],
    };
  },
  watch: {},
  methods: {
    changeIp(e){
      var reg = /\[(.+?)\]/g
      if(reg.test(e.target.value)){
        this.isDisabled = true
        this.$nextTick(()=>{
          this.form.setFieldsValue({
            name:e.target.value
          })
        })
      }else{
        this.isDisabled = false
        this.$nextTick(()=>{
          this.form.setFieldsValue({
            name:''
          })
        })
      }
    },
    syncFormdata (fileds, key, type) {
      this.initFormFiledFlag = true
      const splitArr = key.split(type)
      if (type === 'arrayWithKey') {
        this.hostVals[Number(splitArr[1])].name = fileds[key]
      } else {
        this.hostVals[Number(splitArr[1])].value = fileds[key]
      }
      this.form.getFieldsValue([`${key}`]);
      this.form.setFieldsValue({
        [`${key}`]: fileds[key],
      })
      console.log(this.hostVals)
    },
    checkName(rule, value, callback) {
      var reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (reg.test(value)) {
        callback(new Error("名称中不能包含中文"));
      }
      if (/\s/g.test(value)) {
        callback(new Error("名称中不能包含空格"));
      }
      callback();
    },
    formCancel() {
      this.$destroyAll();
    },
    handleSearch(value){
      this.handleChange(value)
    },
    reduceMultiple (index) {
      this.hostVals.splice(index, 1)
      console.log(this.hostVals, 'cap')
    },
    addMultiple () {
      this.hostVals.push({
        name: '',
        value: '',
        randow: Math.floor(Math.random()*100000)
      })
    },
    handleChange(value){
      let result = (value != null && value != '') ? value : undefined
      this.form.getFieldsValue(['groupId']);
      this.form.setFieldsValue({
        groupId: result
      });
    },
        
    handleBlur(value){
      this.form.getFieldsValue(['groupId']);
      this.form.setFieldsValue({
        groupId: value
      });
    },
    handleSubmit(e) {
      const _this = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          // let reg = /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/
          // if (!reg.test(values.ip)) {
          //   this.$message.warning('IP格式不正确');
          //   return false
          // }
          let vars = []
          this.hostVals.map(item => {
            vars.push({
              name: item.name,
              value: item.value
            })
          })
          const params = {
            ip: values.ip,
            password: values.password,
            port: Number(values.port),
            osType: values.osType,
            name: values.name,
            username: values.username,
            labels: values.labels,
            desc: values.desc,
            groupId: values.groupId,
            vars
          };
          this.loading = true;
          const ajaxApi =
            JSON.stringify(this.detail) !== "{}"
              ? global.API.getHostList + `/${this.detail.id}`
              : global.API.saveHostByGRoup + `${values.groupId}/hosts`;
          if (JSON.stringify(this.detail) !== "{}") {
            this.$axiosPut(ajaxApi, params)
              .then((res) => {
                this.loading = false;
                if ([200, 201, 204, 202].includes(res.status)) {
                  this.$message.success("保存成功", 2);
                  this.$destroyAll();
                  _this.callBack();
                }
              })
              .catch((err) => {});
            return false;
          }
          this.$axiosJsonPost(ajaxApi, params)
            .then((res) => {
              this.loading = false;
              if ([200, 201, 204, 202].includes(res.status)) {
                this.$message.success("保存成功", 2);
                this.$destroyAll();
                _this.callBack();
              }
            })
            .catch((err) => {});
        }
      });
    },
    echoHostDetail() {
      if (JSON.stringify(this.detail) !== "{}") {
        this.editFlag = true;
        if (this.detail.vars.length > 0) {
          this.detail.vars.map(item => {
            this.hostVals.push({
              name: item.name,
              value: item.value,
              randow: Math.floor(Math.random()*100000)
            })
          })
        } else {
          this.hostVals = [{name: '', value: '', randow: Math.floor(Math.random()*100000)}]
        }
        this.labelsList = this.detail.labels
        this.form.getFieldsValue([ "ip", 'passord', 'name', 'username', 'port', 'osType', 'addType', 'authType', 'labels','desc', 'groupId']);
        this.form.setFieldsValue({
          name: this.detail.name,
          ip: this.detail.ip,
          password: this.detail.password && this.detail.password || '',
          port: this.detail.port,
          osType: this.detail.osType,
          username: this.detail.username,
          desc: this.detail.desc,
          addType: 1,
          authType: 1,
          labels: this.detail.labels,
          groupId: Number(this.detail.group_id)
        })
      } else {
        this.hostVals = [{name: '', value: '', randow: Math.floor(Math.random()*100000)}]
        this.editFlag = false;
        this.form.getFieldsValue([ "ip", 'passord', 'name', 'username', 'port', 'osType', 'addType', 'authType', 'labels', 'desc', 'groupId']);
        this.form.setFieldsValue({
          name: '',
          ip: '',
          password: '',
          port: '22',
          osType: undefined,
          username: '',
          addType: 1,
          authType: 1,
          desc: '',
          labels: undefined,
          groupId: undefined
        });
      }
    },
    getHostGroups() {
      const params = {
        page_size: 10000,
        page: 1,
      };
      this.$axiosGet(global.API.getHostGroups, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.hostGroupList = res.data.results
          this.echoHostDetail()
        }
      });
    },
  },
  mounted() {
    this.getHostGroups()
  },
};
</script>
<style lang="less" scoped>
.form-content-host {
  max-height: 580px;
  overflow-y: auto;
}
</style>
