<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2024-01-24 14:30:44
 * @FilePath: \awx-ui\src\pages\hostManage\components\batchServicesModal.vue
-->
<template>
  <div style="padding: 10px 10px 10px 0">
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol" :form="form" class="p0-32-10-32 form-content-host">
      <a-form-item label="添加方式">
        <a-radio-group v-decorator="['addType', { rules: [{ required: true, message: '添加方式不能为空!' }]}]" placeholder="请选择添加方式">
          <a-radio :value="1">追加</a-radio>
          <a-radio :value="2">替换</a-radio>
        </a-radio-group>
      </a-form-item>
      <a-form-item v-for="(child, childIndex) in hostVals" style="margin-bottom: 0px" :key="childIndex" v-bind="childIndex === 0 ? labelCol : formItemLayoutWithOutLabel" :label="childIndex === 0 ? '主机服务' : ''">
        <a-row type="flex" style="position: relative">
          <a-col :span="12">
            <a-form-item style="width: 97%">
              <a-input v-decorator="[
                  `${child.randow + 'arrayWithKey' + childIndex}`,
                  {
                    validateTrigger: ['change', 'blur'],
                    initialValue: child.name,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: `服务名称不能为空!`,
                      },
                    ],
                  },
                ]" placeholder="请输入服务名称" />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item style="width: 97%">
              <a-input v-decorator="[
                  `${child.randow + 'arrayWithValue' + childIndex}`,
                  {
                    validateTrigger: ['change', 'blur'],
                    initialValue: child.value,
                    rules: [
                      {
                        required: true,
                        whitespace: true,
                        message: `服务目录不能为空!`,
                      },
                    ],
                  },
                ]" placeholder="请输入服务目录" />
            </a-form-item>
          </a-col>
          <span style="position: absolute; right: -16px" @click="() => reduceMultiple(childIndex)">
            <svg-icon v-if="hostVals.length > 1" icon-class="reduce-icon" class="reduce-icon" />
          </span>
        </a-row>
      </a-form-item>
      <a-form-item
        class="form-multiple-item"
        v-bind="formItemLayoutWithOutLabel"
        :label="''"
      >
        <a-button type="dashed" @click="() => addMultiple()">
          <a-icon type="plus" />
        </a-button>
      </a-form-item>
    </a-form>
    <div class="ant-modal-confirm-btns-new" style="margin: 0">
      <a-button style="margin-right: 10px" type="primary" @click.stop="handleSubmit" :loading="loading">确认</a-button>
      <a-button @click.stop="formCancel">取消</a-button>
    </div>
  </div>
</template>
  <script>
export default {
  props: {
    ids: {
      type: Array,
      default: function () {
        return [];
      },
    },
    clusterId: String,
    callBack: Function,
  },
  data() {
    const self = this;
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
      initFormFiledFlag: false,
      hostVals: [
      ],
      form: this.$form.createForm(this, {
        onValuesChange: function (props, fileds) {
          if (self.initFormFiledFlag) {
            self.initFormFiledFlag = false;
            return false;
          }
          for (var key in fileds) {
            if (key.includes("arrayWithKey")) {
              self.syncFormdata(fileds, key, "arrayWithKey");
            }
            if (key.includes("arrayWithValue")) {
              self.syncFormdata(fileds, key, "arrayWithValue");
            }
          }
        },
      }),
      loading: false,
      hostGroupList: [],
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 19, offset: 5 },
        },
      },
      labelsList: [],
    };
  },
  watch: {},
  methods: {
    formCancel() {
      this.$destroyAll();
    },
    handleSubmit(e) {
      const _this = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          let params = []
          this.hostVals.map((item) => {
            params.push({
              name: item.name,
              value: item.value,
            });
          });
          this.loading = true;
          const ajaxApi =
            global.API.getHostList + `/${this.ids.join(",")}/variables?cluster_id=${this.clusterId}`;

          if (values.addType === 1) {
            this.$axiosPatch(ajaxApi, params)
              .then((res) => {
                this.loading = false;
                if ([200, 201, 204, 202].includes(res.status)) {
                  this.$message.success("添加服务成功", 2);
                  this.$destroyAll();
                  _this.callBack();
                }
              })
              .catch((err) => {});
            return false;
          }
          this.$axiosPut(ajaxApi, params)
            .then((res) => {
              this.loading = false;
              if ([200, 201, 204, 202].includes(res.status)) {
                this.$message.success("替换服务成功", 2);
                this.$destroyAll();
                _this.callBack();
              }
            })
            .catch((err) => {});
        }
      });
    },
    syncFormdata(fileds, key, type) {
      this.initFormFiledFlag = true;
      const splitArr = key.split(type);
      if (type === "arrayWithKey") {
        this.hostVals[Number(splitArr[1])].name = fileds[key];
      } else {
        this.hostVals[Number(splitArr[1])].value = fileds[key];
      }
      this.form.getFieldsValue([`${key}`]);
      this.form.setFieldsValue({
        [`${key}`]: fileds[key],
      });
      console.log(this.hostVals);
    },
    reduceMultiple(index) {
      this.hostVals.splice(index, 1);
    },
    addMultiple() {
      this.hostVals.push({
        name: "",
        value: "",
        randow: Math.floor(Math.random() * 100000),
      });
    },
    init() {
      this.hostVals.push(
        { name: "", value: "", randow: Math.floor(Math.random() * 100000) },
      )
      this.form.getFieldsValue(['addType']);
      this.form.setFieldsValue({ 
        addType: 1
      });
    },
  },
  mounted() {
    this.init();
  },
};
</script>
  <style lang="less" scoped>
.form-content-host {
  max-height: 580px;
  overflow-y: auto;
}
</style>
  