<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2022-09-07 23:24:07
 * @FilePath: \awx-ui\src\pages\hostManage\components\addGroup.vue
-->
<template>
  <div style="padding-top: 10px">
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol" :form="form" class="p0-32-10-32 form-content">
      <a-form-item label="目标名称">
        <a-input v-decorator="[ 'name',{ rules: [{ required: true, message: '目标名称不能为空!' }] },]" placeholder="请输入目标名称" />
      </a-form-item>
      <a-form-item label="目标类型">
          <a-select v-decorator="['tgt_type',{ rules: [{ required: true, message: '目标类型不能为空!' }] },]" placeholder="请选择目标类型">
              <a-select-option value="grains"> grains </a-select-option>
              <a-select-option value="pillar"> pillar </a-select-option>
              <a-select-option value="compound"> compound </a-select-option>
              <a-select-option value="list"> list </a-select-option>
              <a-select-option value="network"> network </a-select-option>
          </a-select>
          </a-form-item>
      <a-form-item label="目标内容">
         <a-input v-decorator="['tgt',{ rules: [{ required: true, message: '目标内容不能为空!' }] }]" type="textarea" placeholder="请输入目标内容" />
      </a-form-item>
      <a-form-item label="描述">
        <a-input v-decorator="['desc',{ rules: [{ required: false, message: '描述不能为空!' }] }]" type="textarea" placeholder="请输入描述" />
      </a-form-item>
    </a-form>
    <div class="ant-modal-confirm-btns-new">
      <a-button style="margin-right: 10px" type="primary" @click.stop="handleSubmit" :loading="loading">确认</a-button>
      <a-button @click.stop="formCancel">取消</a-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    hostGroupId: Number,
    callBack: Function,
  },
  data() {
    return {
      editFlag: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
      form: this.$form.createForm(this),
      loading: false,
    };
  },
  watch: {},
  methods: {
    checkName(rule, value, callback) {
      var reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (reg.test(value)) {
        callback(new Error("名称中不能包含中文"));
      }
      if (/\s/g.test(value)) {
        callback(new Error("名称中不能包含空格"));
      }
      callback();
    },
    formCancel() {
      this.$destroyAll();
    },
    handleSubmit(e) {
      const _this = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          const params = {
            name: values.name,
            description: values.desc,
            tgt_type:values.tgt_type,
            tgt:values.tgt,
          };
          this.loading = true;
          const ajaxApi = JSON.stringify(this.detail) !== "{}"? global.API.getTargets + `/${this.detail.id}`: global.API.getTargets 
          if (JSON.stringify(this.detail) !== "{}") {
            this.$axiosPatch(ajaxApi, params)
              .then((res) => {
                this.loading = false;
                if ([200, 201, 204, 202].includes(res.status)) {
                  this.$message.success("保存成功", 2);
                  this.$destroyAll();
                  _this.callBack();
                }
              })
              .catch((err) => {});
            return false;
          }
          this.$axiosJsonPost(ajaxApi, params)
            .then((res) => {
              this.loading = false;
              if ([200, 201, 204, 202].includes(res.status)) {
                this.$message.success("保存成功", 2);
                this.$destroyAll();
                _this.callBack();
              }
            })
            .catch((err) => {});
        }
      });
    },
    echoHostDetail() {
      if (JSON.stringify(this.detail) !== "{}") {
        this.editFlag = true;
        this.$axiosGet(API.getHostGroups + `/${this.detail.id}`).then(
          (res) => {
            console.log(res, "sjiku");
            this.form.getFieldsValue([
              "name",
              "tgt",
              "tgt_type",
              "desc",
            ]);
            this.form.setFieldsValue({
              name: this.detail.name,
              desc: this.detail.description,
              tgt_type:this.detail.tgt_type,
              tgt:this.detail.tgt,
            });
          }
        );
      } else {
        this.editFlag = false;
        this.form.getFieldsValue([
              "name",
              "tgt",
              "tgt_type",
              "desc",
        ]);
        this.form.setFieldsValue({
          name: "",
          tgt: "",
          // tgt_type: "",
          desc: "",
        });
      }
    },
  },
  mounted() {
    this.echoHostDetail();
  },
};
</script>
<style lang="less" scoped>
</style>
