<!--
 * @Author: mjzhu
 * @Date: 2022-06-09 10:11:22
 * @LastEditTime: 2023-02-15 15:15:54
 * @FilePath: \awx-ui\src\pages\newHostManage\targetList.vue
-->

<template>
    <div class="host-list">
      <a-card class="table-card">
        <div class="table-info">
          <a-row type="flex" style="margin-bottom: 12px;">
            <a-col :span="12" style="text-align: left">
              <a-button @click="runCommond()">运行命令</a-button>
              <a-button style="margin-left: 10px" @click="onSearch"
                >刷新</a-button
              >
            </a-col>
            <a-col :span="12" style="text-align: right;">
              <a-input-search placeholder="请输入关键词查询" class="w252 mgr12" @change="(value) => getVal(value, 'name')" @search="onSearch" style="width: 400px; height: 30px" />
            </a-col>
          </a-row>
          <a-table class="table-15" @change="tableChange" :row-selection="rowSelection" :columns="columns" :loading="loading" :dataSource="dataSource" rowKey="id" :pagination="pagination"></a-table>
        </div>
      </a-card>
    </div>
  </template>
  
  <script>
  import AddHost from "./components/target/addHost.vue";
  import UploadFile from "./components/target/uploadFile.vue";
  import DeleteHost from "./components/target/deleteHost.vue";
  import BatchLabel from "./components/target/batchLabelModal.vue";
  import { formatBytes } from "@/utils/util";
  import RunCommond from "./components/target/runCommond.vue";
  import { mapActions, mapState } from "vuex";
  
  import moment from "moment";
  export default {
    name: "HOSTLIST",
    props: {
      titleId: String,
    },
    watch: {
      titleId: {
        handler(val) {
          this.showAdd = val;
          this.onSearch();
        },
        immediate: true,
      },
    },
    data() {
      return {
        params: {},
        pagination: {
          total: 0,
          pageSize: 15,
          current: 1,
          showTotal: (total) => `共 ${total} 条`,
        },
        showAdd: false,
        dataSource: [],
        loading: false,
        selectedRowKeys: [],
        selectedRows: [],
        columns: [
          {
            title: "序号",
            key: "index",
            width: 70,
            customRender: (text, row, index) => {
              return (
                <span>
                  {parseInt(
                    this.pagination.current === 1
                      ? index + 1
                      : index +
                          1 +
                          this.pagination.pageSize * (this.pagination.current - 1)
                  )}
                </span>
              );
            },
          },
          // { title: "状态", key: "name", dataIndex: "name" },
          {
            title: "主机标识",
            key: "nodename",
            dataIndex: "nodename",
            width: 200,
          },
          {
            title: "IP",
            key: "ipv4",
            dataIndex: "ipv4",
            ellipsis: true,
            customRender: (text, row, index) => {
              return (
                <span>
                  <a-tooltip placement="topLeft" title={text.join(" ,")}>
                    {" "}
                    {text.join(" ,")}
                  </a-tooltip>
                </span>
              );
            },
          },
          // {
          //   title: "master",
          //   key: "osType",
          //   dataIndex: "osType"
          // },
          {
            title: "操作系统",
            key: "os",
            dataIndex: "os",
            width: 140,
          },
          {
            title: "系统版本",
            key: "osrelease",
            dataIndex: "osrelease",
            width: 160,
          },
          {
            title: "CPU数量",
            key: "num_cpus",
            dataIndex: "num_cpus",
            width: 140,
          },
          // {
          //   title: "硬件架构",
          //   key: "updated_at",
          //   dataIndex: "updated_at",
          //   width: 180,
          //   customRender: (text) => {
          //     return <span>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</span>;
          //   },
          // },
          {
            title: "内存大小",
            key: "mem_total",
            dataIndex: "mem_total",
            width: 140,
            customRender: (text, row, index) => {
              return <span>{text ? formatBytes(text * 1024 * 1024) : "-"}</span>;
            },
          },
          {
            title: "版本号",
            key: "saltversion",
            dataIndex: "saltversion",
            width: 140,
          },
        ],
      };
    },
    computed: {
      //复选框 需要时候再加
      rowSelection() {
        const { selectedRowKeys } = this;
        let _this = this;
        return {
          selectedRowKeys,
          onSelectAll: (selectedRowKeys, selectedRows) => {
            _this.selectedRows = selectedRows;
          },
          onChange: (selectedRowKeys, selectedRows) => {
            _this.selectedRowKeys = selectedRowKeys;
            _this.selectedRows = selectedRows;
            console.log(
              `selectedRowKeys: ${selectedRowKeys}`,
              "selectedRows: ",
              selectedRows
            );
          },
          getCheckboxProps: (record) => {
            return {
              props: {
                name: record.name,
              },
            };
          },
        };
      },
    },
    methods: {
      ...mapActions("runHistory", ["getHistoryList", "setRunningFlag", "setRunTab", "setNumAdd"]),
      // 运行命令
      runCommond() {
        if (this.selectedRows.length == 0) {
          this.$message.warning("请选择一条数据");
          return false;
        }
        var ids = [];
        this.selectedRows.map((item) => {
          ids.push(item.nodename);
        });
        const self = this;
        let width = 560;
        let title = "运行命令";
        let content = (
          <RunCommond
            ids={ids}
            callBack={() => {
              self.onSearch();
              self.selectedRowKeys = [];
              self.selectedRows = [];
              self.setRunningFlag(true)
              self.setRunTab('cmd')
              // 刷新列表 把分页置位1
              self.setNumAdd(true)
            }}
          />
        );
        this.$confirm({
          width: width,
          title: title,
          content: content,
          closable: true,
          icon: () => {
            return <div />;
          },
        });
      },
      delHost() {
        const self = this;
        if (this.selectedRows.length == 0) {
          this.$message.warning("请选择一条数据");
          return false;
        }
        var ids = [];
        this.selectedRows.map((item) => {
          ids.push(item.id);
        });
        let width = 400;
        let content = (
          <DeleteHost
            sysTypeTxt="目标"
            detail={{ ids: ids }}
            type="host"
            refreshList={() => {
              self.onSearch();
              self.selectedRowKeys = [];
              self.selectedRows = [];
            }}
          />
        );
        this.$confirm({
          width: width,
          title: () => {
            return (
              <div>
                <a-icon
                  type="question-circle"
                  style="color:#2F7FD1 !important;margin-right:10px"
                />
                提示
              </div>
            );
          },
          content,
          closable: true,
          icon: () => {
            return <div />;
          },
        });
      },
      tableChange(pagination) {
        this.pagination.current = pagination.current;
        this.getHostList();
      },
      getVal(val, filed) {
        this.params[`${filed}`] = val.target.value;
      },
      //   查询
      onSearch() {
        this.pagination.current = 1;
        this.getHostList();
      },
      batchLabel() {
        if (this.selectedRows.length == 0) {
          this.$message.warning("请选择一条数据");
          return false;
        }
        var ids = [];
        this.selectedRows.map((item) => {
          ids.push(item.id);
        });
        const self = this;
        let width = 560;
        let title = "添加标签";
        let content = (
          <BatchLabel
            ids={ids}
            callBack={() => {
              self.onSearch();
              self.selectedRowKeys = [];
              self.selectedRows = [];
            }}
          />
        );
        this.$confirm({
          width: width,
          title: title,
          content: content,
          closable: true,
          icon: () => {
            return <div />;
          },
        });
      },
      uploadHost() {
        const self = this;
        let width = 560;
        let title = "导入";
        let content = (
          <UploadFile
            callBack={() => {
              self.onSearch();
              self.$emit("getGroupList");
            }}
          />
        );
        this.$confirm({
          width: width,
          title: title,
          content: content,
          closable: true,
          icon: () => {
            return <div />;
          },
        });
      },
      createHost(obj) {
        const self = this;
        let width = 560;
        let title = JSON.stringify(obj) === "{}" ? "添加目标" : "编辑";
        let content = (
          <AddHost
            titleId={this.titleId}
            detail={obj}
            callBack={() => {
              self.getHostList();
              self.$emit("getGroupList");
            }}
          />
        );
        this.$confirm({
          width: width,
          title: title,
          content: content,
          closable: true,
          icon: () => {
            return <div />;
          },
        });
      },
      deleteHost(obj) {
        const self = this;
        let width = 400;
        let content = (
          <DeleteHost
            sysTypeTxt="目标"
            detail={obj}
            type="host"
            refreshList={() => self.getHostList()}
          />
        );
        this.$confirm({
          width: width,
          title: () => {
            return (
              <div>
                <a-icon
                  type="question-circle"
                  style="color:#2F7FD1 !important;margin-right:10px"
                />
                提示
              </div>
            );
          },
          content,
          closable: true,
          icon: () => {
            return <div />;
          },
        });
      },
      getHostList() {
        // if (!this.titleId) return;
        this.loading = true;
        const params = {
          page_size: this.pagination.pageSize,
          page: this.pagination.current,
          keyword: this.params.name || "",
        };
        let apiAjax = this.titleId
          ? global.API.getTargets + `/${this.titleId}/minions`
          : global.API.getTargets + `/all/minions`;
        this.$axiosGet(apiAjax, params).then((res) => {
          this.loading = false;
          console.log(res);
          if ([200, 201, 204, 202].includes(res.status)) {
              this.dataSource = res.data ? res.data: [];
              this.pagination.total = res.data.length || 0;
          }
        });
      },
    },
    mounted() {
      this.getHostList();
    },
  };
  </script>
  
  <style lang="less" scoped>
  /deep/.w252 {
    margin-right: 0;
  }
  .host-list {
    /deep/ .ant-card-body {
      padding: 16px 20px 20px 20px;
    }
    height: calc(100vh - 48px);
    background: #F7F8FA;

    .table-card {
      height: calc(100%);
      box-shadow: none;
      border: none;
    }
    .btn-opt {
      border-radius: 2px;
      font-size: 12px;
      color: #0264c8;
      letter-spacing: 0;
      font-weight: 400;
      margin: 0 5px;
    }
  }
  /deep/.ant-input-affix-wrapper {
    input {
      height: 30px;
    }
  }
  /deep/.ant-input {
    border-radius: 4px;
  }
  /deep/.ant-card-body {
    padding-top: 12px;
    display: flex;
    justify-content: flex-end;
  }
  /deep/.ant-btn {
    height: 30px;
  }
  </style>
  