<!--
 * @Author: mjzhu
 * @Date: 2022-06-09 10:11:22
 * @LastEditTime: 2024-01-26 15:23:52
 * @FilePath: \awx-ui\src\pages\newHostManage\hostList.vue
-->

<template>
  <div class="host-list">
    <a-card class="table-card">
      <div class="table-info">
        <a-row type="flex" style="margin-bottom: 12px">
          <a-col :span="clientWidth < 1700 ? 20 : 18" style="text-align: left">
            <a-button @click="runCommond()">运行命令</a-button>
            <a-button style="margin-left: 10px" @click="batchLabel()">添加标签</a-button>
            <a-button style="margin-left: 10px" @click="batchService()">添加服务</a-button>
            <a-button style="margin-left: 10px" @click="fileManage()">文件管理</a-button>
            <a-button style="margin-left: 10px" @click="delHost()">删除</a-button>
            <a-button style="margin-left: 10px" @click="onSearch">刷新</a-button>
            <a-button class="mgl10" type="primary" style="z-index: 1" @click="createHost({})"><svg-icon class="btn-before-icon mgr6" icon-class="file-add"></svg-icon>添加主机</a-button>
            <a-button class="mgl10" type="primary" style="z-index: 1" @click="importHost({})"><svg-icon style="margin-right: 8px;" class="btn-before-icon" icon-class="host-import"></svg-icon>导入主机</a-button>
          </a-col>
          <a-col :span="clientWidth < 1700 ? 4 : 6" style="text-align: right">
            <a-input-search placeholder="请输入关键词查询" @change="(value) => getVal(value, 'name')" @search="onSearch" />
          </a-col>
        </a-row>
        <a-table class="opt-table table-15" @change="tableChange" :row-selection="rowSelection" :columns="columns" :loading="loading" :dataSource="dataSource" :customRow="handleClickRow" rowKey="id" :pagination="pagination"></a-table>
      </div>
    </a-card>
  </div>
</template>
  
  <script>
import AddHost from "./components/host/addHost.vue";
import UploadFile from "./components/host/uploadFile.vue";
import DeleteHost from "./components/host/deleteHost.vue";
import BatchLabel from "./components/host/batchLabelModal.vue";
import BatchServices from "./components/host/batchServicesModal.vue";
import RunCommond from "./components/host/runCommond.vue";
import { mapActions, mapState, mapGetters} from "vuex";
import HostEmpty from "@/components/empty-host/index.vue";
const clientWidth = document.documentElement.clientWidth

import moment from "moment";
export default {
  name: "HOSTLIST",
  props: {
    hostGroupId: Number,
  },
  watch: {
    hostGroupId: {
      handler(val) {
        this.showAdd = val;
        this.onSearch();
      },
      immediate: true,
    },
  },
  data() {
    return {
      clientWidth,
      params: {},
      pagination: {
        total: 0,
        pageSize: 15,
        current: 1,
        showTotal: (total) => `共 ${total} 条`,
      },
      cluster_id: '',
      setSelectedIndex: -1,
      showAdd: false,
      dataSource: [],
      loading: false,
      selectedRowKeys: [],
      selectedRows: [],
      orderBy: "",
      columns: [
        {
          title: "序号",
          key: "index",
          width: 64,
          customRender: (text, row, index) => {
            return (
              <span>
                {parseInt(
                  this.pagination.current === 1
                    ? index + 1
                    : index +
                        1 +
                        this.pagination.pageSize * (this.pagination.current - 1)
                )}
              </span>
            );
          },
        },
        {
          title: "主机名",
          key: "name",
          dataIndex: "name",
          width: 140,
          sorter: true,
          ellipsis: true,
          customRender: (text, row, index) => {
            return <span title={text}>{text ? text : "-"}</span>;
          },
        },
        {
          title: "主机IP",
          key: "ip",
          width: 140,
          ellipsis: true,
          dataIndex: "ip",
          customRender: (text, row, index) => {
            return <span title={text}>{text ? text : "-"}</span>;
          },
        },
        {
          title: "端口",
          key: "port",
          width: 64,
          ellipsis: true,
          dataIndex: "port",
        },
        {
          title: "操作系统",
          key: "osType",
          width: 120,
          ellipsis: true,
          dataIndex: "osType",
          customRender: (text, row, index) => {
            return <span>
              <svg-icon iconClass={text === 'Linux' ? 'linux' : 'windows'}></svg-icon>
                <span class="mgl8" title={ text }>{text ? text : '-'}</span>
              </span>
          },
        },
        {
          title: "所属集群",
          key: "master_name",
          width: 120,
          ellipsis: true,
          dataIndex: "master_name",
          customRender: (text, row, index) => {
            return <span title={text}>{text ? text : "-"}</span>;
          },
        },
        {
          title: "主机标签",
          key: "labels",
          ellipsis: true,
          dataIndex: "labels",
          customRender: (text, row, index) => {
            return <span title={ text.join(",") }>{text ? text.join(",") : '-'}</span>;
          },
        },
        {
          title: "主机服务",
          key: "vars",
          dataIndex: "vars",
          ellipsis: true,
          customRender: (text, row, index) => {
            return (
              <span title={this.handlerVars(text)}>
                {this.handlerVars(text)}
              </span>
            );
          },
        },
        {
          title: "主机组",
          key: "group_name",
          dataIndex: "group_name",
          ellipsis: true,
          customRender: (text, row, index) => {
            return <span title={ text }>{text ? text : "-"}</span>;
          },
        },
        {
          title: "更新时间",
          key: "updated_at",
          ellipsis: true,
          dataIndex: "updated_at",
          sorter: true,
          customRender: (text) => {
            return (
              <span
                class="ell"
                title={moment(text).format("YYYY-MM-DD HH:mm:ss")}
              >
                {moment(text).format("YYYY-MM-DD HH:mm:ss")}
              </span>
            );
          },
        },
        {
          title: "操作",
          key: "action",
          width: 126,
          customRender: (text, row, index) => {
            return (
              <span class="flex-container">
                <a class="btn-opt" onClick={(e) => this.createHost(row, e)}>
                  编辑
                </a>
                <a-divider type="vertical" />
                <a class="btn-opt" onClick={(e) => this.deleteHost(row, e)}>
                  删除
                </a>
              </span>
            );
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters("masterMd", ["hasMaster"]),
    //复选框 需要时候再加
    rowSelection() {
      const { selectedRowKeys } = this;
      let _this = this;
      return {
        selectedRowKeys,
        onSelectAll: (selectedRowKeys, selectedRows) => {
          _this.selectedRows = selectedRows;
        },
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys;
          _this.selectedRows = selectedRows;
        },
        getCheckboxProps: (record) => {
          return {
            props: {
              name: record.name,
            },
          };
        },
      };
    }
  },
  methods: {
    ...mapActions("runHistory", [
      "getHistoryList",
      "setRunningFlag",
      "setRunTab",
      "setNumAdd",
    ]),
    ...mapActions("masterMd", ["setHasMaster"]),
    creatMaster() {
      this.$destroyAll();
    },
    handleClickRow(record, index) {
      return {
        style: {
          "background-color":
            index === this.setSelectedIndex ? "#e4eefd " : "#fff",
        },
        on: {
          click: () => {
            this.setSelectedIndex = index;
            this.createHost(record);
          },
        },
      };
    },
    clearSelectIndex() {
      this.setSelectedIndex = -1;
    },
    // 运行命令
    runCommond() {
      if (this.selectedRows.length == 0) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      var ids = [];
      this.selectedRows.map((item) => {
        ids.push(item.id + "");
      });
      const self = this;
      let width = 560;
      let title = "运行命令";
      let content = (
        <RunCommond
          ids={ids}
          clusterId={this.cluster_id}
          callBack={() => {
            self.onSearch();
            self.selectedRowKeys = [];
            self.selectedRows = [];
            self.setRunningFlag(true);
            self.setRunTab("cmd");
            // 刷新列表 把分页置位1
            self.setNumAdd(true);
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    handlerVars(vars) {
      let str = "";
      vars.map((item) => {
        let name = item["name"] ? item["name"] + ":  " : "";
        let value = item["value"] ? item["value"] + ",   " : "";
        str += name + value;
      });
      return str.slice(0, str.length - 4) || '-'
    },
    fileManage() {
      if (this.selectedRows.length == 0) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      var ids = [];
      const params = {};
      this.selectedRows.map((item) => {
        params.id = item.id;
        ids.push({
          id: item.id,
        });
      });
      const ajaxApi = global.API.addFilesByHost;
      this.$axiosJsonProxyPost(ajaxApi, ids)
        .then((res) => {
          this.loading = false;
          if ([200, 201, 204, 202].includes(res.status)) {
            if (res.data && res.data.length > 0) {
              this.$message.success("操作成功", 2);
              this.$router.push({name: "文件", params: {
                filesystemId: res.data[0].filesystem.id
              }});
            } else {
              this.$message.error("操作失败", 2);
            }
          } else {
            this.$message.error("操作失败", 2);
          }
        })
        .catch((err) => {
          // this.$message.error("操作失败", 2);
        });
    },
    delHost() {
      const self = this;
      if (this.selectedRows.length == 0) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      var ids = [];
      this.selectedRows.map((item) => {
        ids.push(item.id);
      });
      let width = 400;
      let content = (
        <DeleteHost
          sysTypeTxt="主机"
          detail={{ ids: ids }}
          type="host"
          clusterId={this.cluster_id}
          refreshList={() => {
            self.onSearch();
            self.selectedRowKeys = [];
            self.selectedRows = [];
          }}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    tableChange(pagination, filter, sorter) {
      const { order, columnKey } = sorter;
      console.log(order, columnKey);
      if (this.pagination.current !== pagination.current) {
        this.pagination.current = pagination.current;
      }
      if (order) {
        if (order === "ascend") {
          this.orderBy = columnKey;
        } else {
          this.orderBy = "-" + columnKey;
        }
      } else {
        this.orderBy = "";
      }
      this.getHostList();
    },
    getVal(val, filed) {
      this.params[`${filed}`] = val.target.value;
    },
    //   查询
    onSearch() {
      this.pagination.current = 1;
      this.getHostList();
    },
    batchService() {
      if (this.selectedRows.length == 0) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      var ids = [];
      this.selectedRows.map((item) => {
        ids.push(item.id);
      });
      const self = this;
      let width = 560;
      let title = "添加服务";
      let content = (
        <BatchServices
          ids={ids}
          clusterId={this.cluster_id}
          callBack={() => {
            self.onSearch();
            self.selectedRowKeys = [];
            self.selectedRows = [];
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    batchLabel() {
      if (this.selectedRows.length == 0) {
        this.$message.warning("请选择一条数据");
        return false;
      }
      var ids = [];
      this.selectedRows.map((item) => {
        ids.push(item.id);
      });
      const self = this;
      let width = 560;
      let title = "添加标签";
      let content = (
        <BatchLabel
          ids={ids}
          clusterId={this.cluster_id}
          callBack={() => {
            self.onSearch();
            self.selectedRowKeys = [];
            self.selectedRows = [];
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    uploadHost() {
      const self = this;
      let width = 560;
      let title = "导入";
      let content = (
        <UploadFile
          clusterId={this.cluster_id}
          callBack={() => {
            self.onSearch();
            self.$emit("getGroupList");
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    importHost() {
      const self = this;
      let width = 1081;
      let title = "";
      let content = (
        <HostEmpty
          style="height: 650px"
          clusterId={this.cluster_id}
          hasMaster={this.hasMaster}
          creatMaster={() => {
            self.onSearch();
            self.$emit("getGroupList");
            self.creatMaster();
          }}
        />
      );
      this.$confirm({
        width: width,
        class: "host-empty-dialog",
        title: title,
        content: content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    createHost(obj, e) {
      if (e) e.stopPropagation();
      const self = this;
      let width = 630;
      let title = JSON.stringify(obj) === "{}" ? "添加主机" : "编辑";
      let content = (
        <AddHost
          hostGroupId={this.hostGroupId}
          detail={obj}
          clusterId={this.cluster_id}
          clearSelectIndex={() => self.clearSelectIndex()}
          hasMaster={self.hasMaster}
          callBack={() => {
            self.getHostList();
            self.$emit("getGroupList");
          }}
        />
      );
      this.$confirm({
        width: width,
        title: title,
        content: content,
        closable: true,
        onCancel: () => {
          self.clearSelectIndex();
        },
        icon: () => {
          return <div />;
        },
      });
    },
    deleteHost(obj, e) {
      if (e) e.stopPropagation();
      const self = this;
      let width = 400;
      let content = (
        <DeleteHost
          sysTypeTxt="主机"
          detail={obj}
          clusterId={this.cluster_id}
          type="host"
          refreshList={() => self.getHostList()}
        />
      );
      this.$confirm({
        width: width,
        title: () => {
          return (
            <div>
              <a-icon
                type="question-circle"
                style="color:#2F7FD1 !important;margin-right:10px"
              />
              提示
            </div>
          );
        },
        content,
        closable: true,
        icon: () => {
          return <div />;
        },
      });
    },
    getHostList() {
      if (!this.cluster_id) return false
      this.loading = true;
      const params = {
        page_size: this.pagination.pageSize,
        page: this.pagination.current,
        keyword: this.params.name || "",
        order_by: this.orderBy,
        cluster_id: this.cluster_id
      };
      let apiAjax = this.hostGroupId
        ? global.API.getHostGroups + `/${this.hostGroupId}/hosts`
        : global.API.getHostList;
      this.$axiosGet(apiAjax, params).then((res) => {
        this.loading = false;
        console.log(res);
        if ([200, 201, 204, 202].includes(res.status)) {
          this.dataSource = res.data.results;
          this.pagination.total = res.data.total;
        }
      });
    },
  },
  mounted() {
    this.$EventBus.$on("getHostList", (cluster_id) => {
      this.cluster_id = cluster_id
      this.getHostList();
    });
  },
  beforeDestroy() {
    this.$EventBus.$off("getHostList");
  },
};
</script>

  <style lang="less" scoped>
.host-list {
  /deep/ .ant-card-body {
    padding: 16px 20px 20px 20px;
  }
  height: calc(100vh - 48px);
  background: #F7F8FA;
  .table-card {
    height: calc(100%);
    box-shadow: none;
    border: none;
  }
  .btn-opt {
    border-radius: 2px;
    font-size: 12px;
    color: #0264c8;
    letter-spacing: 0;
    font-weight: 400;
  }
  .btn-fixed {
    position: fixed;
    right: 20px;
    top: 56px;
  }
}
/deep/.ant-input-affix-wrapper {
  input {
    height: 30px;
  }
}
/deep/.ant-input {
  border-radius: 4px;
}
/deep/.ant-card-body {
  padding-top: 12px;
  // display: flex;
  // justify-content: flex-end;
}
/deep/.w252 {
  margin-right: 0;
}
/deep/.ant-btn {
  height: 30px;
}
</style>
  