<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2024-01-26 14:01:52
 * @FilePath: \awx-ui\src\pages\newHostManage\components\host\addHost.vue
-->
<template>
  <div style="padding: 10px 10px 10px 0">
    <a-form
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
      :form="form"
      class="p12-32-10-32 form-content-host"
    >
      <a-form-item label="主机分组">
        <a-select
          :show-search="true"
          :not-found-content="null"
          :filter-option="true"
          :allowClear="true"
          v-decorator="[
            'groupId',
            { rules: [{ required: true, message: '主机组不能为空!' }] },
          ]"
          placeholder="请选择或输入主机组"
          @search="handleSearch"
          @blur="handleBlur"
          @change="handleChange"
        >
          <a-select-option
            :value="item.id"
            v-for="(item, index) in hostGroupList"
            :key="index"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </a-form-item>
      <a-form-item label="主机ip">
        <a-input
          @change="changeIp"
          v-decorator="[
            'ip',
            { rules: [{ required: true, message: 'IP地址不能为空!' }] },
          ]"
          placeholder="请输入IP地址"
        />
      </a-form-item>
      <a-form-item label="主机名称">
        <a-input
          :disabled="isDisabled"
          v-decorator="[
            'name',
            { rules: [{ required: true, message: '主机名不能为空!' }] },
          ]"
          placeholder="请输入主机名"
        />
      </a-form-item>
      <a-form-item label="管理节点">
        <a-select
          :style="{ width: pageFalse === 'true' ? '100%' : '94%' }"
          v-decorator="[
            'master',
            {
              rules: [
                {
                  required: true,
                  message: '请选择管理节点!',
                },
              ],
            },
          ]"
        >
          <a-select-option
            v-for="(item, index) in masterSelectList"
            :key="index"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-icon
          v-if="pageFalse === 'false'"
          @click="openDialog"
          style="margin-left: 8px; cursor: pointer"
          type="plus"
        />
      </a-form-item>
      <a-form-item
        style="margin-bottom: 0px"
        label="操作系统"
      >
        <a-row type="flex" style="position: relative">
          <a-col :span="12">
            <a-form-item style="width: 97%">
              <a-select
                v-decorator="[
                  'osType',
                  { rules: [{ required: true, message: '操作系统不能为空!' }] },
                ]"
                placeholder="请选择操作系统"
              >
              <a-select-option
                :value="item.value"
                v-for="(item, index) in osTypeList"
                :key="index"
                >{{ item.label }}</a-select-option
              >
            </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item class="mgl6" style="width: 97%">
              <a-input
              v-decorator="[
                'port',
                { rules: [{ required: true, message: '端口号不能为空!' }] },
              ]"
              placeholder="请输入端口号，如：22"
            />
            </a-form-item>
          </a-col>
        </a-row>
      </a-form-item>
      <a-form-item label="用户名称">
        <a-input
          type="username"
          v-decorator="[
            'username',
            { rules: [{ required: true, message: '用户名不能为空!' }] },
          ]"
          placeholder="请输入用户名"
        />
      </a-form-item>
      <a-form-item label="认证方式">
        <a-radio-group
          v-decorator="[
            'auth_type',
            { rules: [{ required: true, message: '认证方式不能为空!' }] },
          ]"
          placeholder="请选择认证方式"
        >
          <a-radio-button value="password">密码</a-radio-button>
          <a-radio-button value="private_key">密钥</a-radio-button>
        </a-radio-group>
      </a-form-item>
      <a-form-item v-if="!showAuthFlag" label="用户密码">
        <a-input
          v-if="!isShow"
          type="password"
          v-decorator="[
            'password',
            { rules: [{ required: !editFlag, message: '密码不能为空!' }] },
          ]"
          placeholder="请输入密码"
        />
        <a-input
          v-else
          v-decorator="[
            'password',
            { rules: [{ required: !editFlag, message: '密码不能为空!' }] },
          ]"
          placeholder="请输入密码"
        />
        <span  style="position: absolute; cursor: pointer; right: 14px; top: -6px" @click.stop="isShow = !isShow">
          <svg-icon :icon-class="!isShow ? 'eye-invisible' : 'eye'" style="font-size: 18px"></svg-icon>
        </span>
      </a-form-item>
      <a-form-item label="密钥" v-if="showAuthFlag">
        <a-input
          v-decorator="[
            'private_key',
            { rules: [{ required: true, message: '密钥不能为空!' }] },
          ]"
          placeholder="请输入密钥"
        />
      </a-form-item>
      <a-form-item
        class="form-multiple-item"
        v-bind="formItemLayoutWithOutLabel"
        :label="''"
      >
        
      </a-form-item>
      <a-form-item label="主机描述">
        <a-input
          v-decorator="[
            'desc',
            { rules: [{ required: false, message: '描述不能为空!' }] },
          ]"
          type="textarea"
          placeholder="请输入描述"
        />
      </a-form-item>
    </a-form>
    <div class="ant-modal-confirm-btns-new" style="margin: 0">
      <a-button  @click.stop="formCancel">取消</a-button>
      <a-button
      class="mgl10"
        type="primary"
        @click.stop="handleSubmit"
        :loading="loading"
        >保存</a-button
      >
    </div>
    <a-modal v-if="visible" v-model="visible" title="配置Master" @ok="handleOk">
      <configMaster
        @creatMaster="creatMaster"
        @refreshList="getMaster"
      ></configMaster>
      <!-- @creatMaster="creatMaster"
        @refreshList="getMaster" -->
    </a-modal>
  </div>
</template>
<script>

export default {
  props: {
    detail: {
      type: Object,
      default: function () {
        return {};
      },
    },
    hostGroupId: Number,
    callBack: Function,
    clearSelectIndex: Function,
    hasMaster: {
      type: String,
      default: 'true'
    },
    clusterId: String
  },
  data() {
    const self = this;
    return {
      pageFalse: 'true',
      visible: false,
      isDisabled: false,
      isShow: false,
      editFlag: false,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
      },
      initFormFiledFlag: false,
      hostVals: [
        { name: "", value: "", randow: Math.floor(Math.random() * 100000) },
      ],
      form: this.$form.createForm(this, {
        onValuesChange: function (props, fileds) {
          if (self.initFormFiledFlag) {
            self.initFormFiledFlag = false;
            return false;
          }
          for (var key in fileds) {
            if (key.includes("arrayWithKey")) {
              self.syncFormdata(fileds, key, "arrayWithKey");
            }
            if (key.includes("arrayWithValue")) {
              self.syncFormdata(fileds, key, "arrayWithValue");
            }
            if(key.includes('auth_type')) {
              if (fileds[key] === 'password') {
                self.showAuthFlag = false
              } else {
                self.showAuthFlag = true
              }
            }
          }
        },
      }),
      showAuthFlag: false,
      loading: false,
      hostGroupList: [],
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 17, offset: 5 },
        },
      },
      labelsList: [],
      osTypeList: [
        {
          label: "Linux",
          value: "Linux",
        },
        {
          label: "Windows",
          value: "Windows",
        },
      ],
      masterSelectList: [],
    };
  },
  watch: {
  },
  computed: {  
    isEdit () {
      return JSON.stringify(this.detail) !== "{}"
    }
  },
  methods: {
    openDialog() {
      if (this.hasMaster === 'true') return;
      this.visible = true;
    },
    creatMaster(val) {
      if (this.hasMaster === 'false') return;
      val.type == "creat"
        ? this.$axiosJsonPost(global.API.creatMaster, val.params).then(
            (res) => {
              this.getMaster();
            }
          )
        : (this.visible = false);
    },
    handleOk() {},
    getMaster() {
      this.$axiosGet(global.API.getMasterInfo).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.masterSelectList = res.data.results.filter(item => item.status === 'online');
          this.$nextTick(() => {
            this.echoHostDetail();
          });
        }
      });
    },
    changeIp(e) {
      var reg = /\[(.+?)\]/g;
      if (reg.test(e.target.value)) {
        this.isDisabled = true;
        this.$nextTick(() => {
          this.form.setFieldsValue({
            name: e.target.value,
          });
        });
      } else {
        this.isDisabled = false;
        this.$nextTick(() => {
          this.form.setFieldsValue({
            name: "",
          });
        });
      }
    },
    syncFormdata(fileds, key, type) {
      this.initFormFiledFlag = true;
      const splitArr = key.split(type);
      if (type === "arrayWithKey") {
        this.hostVals[Number(splitArr[1])].name = fileds[key];
      } else {
        this.hostVals[Number(splitArr[1])].value = fileds[key];
      }
      this.form.getFieldsValue([`${key}`]);
      this.form.setFieldsValue({
        [`${key}`]: fileds[key],
      });
      console.log(this.hostVals);
    },
    checkName(rule, value, callback) {
      var reg = /[\u4E00-\u9FA5]|[\uFE30-\uFFA0]/g;
      if (reg.test(value)) {
        callback(new Error("名称中不能包含中文"));
      }
      if (/\s/g.test(value)) {
        callback(new Error("名称中不能包含空格"));
      }
      callback();
    },
    formCancel() {
      this.$destroyAll();
      this.clearSelectIndex()
    },
    reduceMultiple(index) {
      this.hostVals.splice(index, 1);
      console.log(this.hostVals, "cap");
    },
    addMultiple() {
      this.hostVals.push({
        name: "",
        value: "",
        randow: Math.floor(Math.random() * 100000),
      });
    },
    handleSearch(value) {
      this.handleChange(value);
    },
    handleChange(value) {
      let result = value != null && value != "" ? value : undefined;
      this.form.getFieldsValue(["groupId"]);
      this.form.setFieldsValue({
        groupId: result,
      });
    },
    handleBlur(value) {
      this.form.getFieldsValue(["groupId"]);
      this.form.setFieldsValue({
        groupId: value,
      });
    },
    handleSubmit(e) {
      const _this = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          // let reg = /^((25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))\.){3}(25[0-5]|2[0-4]\d|((1\d{2})|([1-9]?\d)))$/
          // if (!reg.test(values.ip)) {
          //   this.$message.warning('IP格式不正确');
          //   return false
          // }
          let vars = [];
          this.hostVals.map((item) => {
            vars.push({
              name: item.name,
              value: item.value,
            });
          });
          const params = {
            ip: values.ip,
            port: Number(values.port),
            osType: values.osType,
            name: values.name,
            labels: values.labels,
            desc: values.desc,
            groupId: values.groupId,
            vars,
            master: values.master,
            auth_type: values.auth_type,
            username: values.username
          };
          if (params.auth_type === 'password') {
            params.password = values.password
          } else {
            params.private_key = values.private_key
          }
          this.loading = true;
          const ajaxApi =
            JSON.stringify(this.detail) !== "{}"
              ? global.API.getHostList + `/${this.detail.id}` + '?cluster_id=' + this.clusterId
              : global.API.saveHostByGRoup + `${values.groupId}/hosts` + '?cluster_id=' + this.clusterId;
          if (JSON.stringify(this.detail) !== "{}") {
            this.$axiosPut(ajaxApi, params)
              .then((res) => {
                this.loading = false;
                if ([200, 201, 204, 202].includes(res.status)) {
                  this.$message.success("保存成功", 2);
                  this.$destroyAll();
                  _this.clearSelectIndex()
                  _this.callBack();
                }
              })
              .catch((err) => {});
            return false;
          }
          this.$axiosJsonPost(ajaxApi, params)
            .then((res) => {
              this.loading = false;
              if ([200, 201, 204, 202].includes(res.status)) {
                this.$message.success("保存成功", 2);
                this.$destroyAll();
                _this.clearSelectIndex()
                _this.callBack();
              }
            })
            .catch((err) => {});
        }
      });
    },
    echoHostDetail() {
      if (JSON.stringify(this.detail) !== "{}") {
        this.editFlag = true;
        if (this.detail.vars.length > 0) {
          this.hostVals = []
          this.detail.vars.map((item) => {
            this.hostVals.push({
              name: item.name,
              value: item.value,
              randow: Math.floor(Math.random() * 100000),
            });
          });
        } else {
          this.hostVals = [
            { name: "", value: "", randow: Math.floor(Math.random() * 100000) },
          ];
        }
        this.labelsList = this.detail.labels;
        this.form.getFieldsValue([
          "ip",
          "passord",
          "name",
          "username",
          "port",
          "osType",
          "addType",
          "auth_type",
          "labels",
          "desc",
          "groupId",
          "master",
        ]);
        this.form.setFieldsValue({
          name: this.detail.name,
          ip: this.detail.ip,
          password: (this.detail.password && this.detail.password) || "",
          port: this.detail.port,
          osType: this.detail.osType,
          username: this.detail.username,
          desc: this.detail.desc,
          addType: 1,
          auth_type: this.detail.auth_type ? this.detail.auth_type : 'password',
          labels: this.detail.labels,
          groupId: Number(this.detail.group_id),
          master: this.detail.master || undefined,
          private_key: this.detail.privatekey || ''
        });
      } else {
        this.hostVals = [
          { name: "", value: "", randow: Math.floor(Math.random() * 100000) },
        ];
        this.editFlag = false;
        this.form.getFieldsValue([
          "ip",
          "passord",
          "name",
          "username",
          "port",
          "osType",
          "addType",
          "auth_type",
          "labels",
          "desc",
          "groupId",
          "master",
        ]);
        this.form.setFieldsValue({
          name: "",
          ip: "",
          password: "",
          port: "22",
          osType: undefined,
          username: "",
          addType: 1,
          auth_type: 'password',
          desc: "",
          private_key: '',
          labels: undefined,
          groupId: 'default',
          master:this.clusterId || undefined,
        });
      }
    },
    getHostGroups() {
      const params = {
        page_size: 10000,
        page: 1,
        cluster_id: this.clusterId
      };
      this.$axiosGet(global.API.getHostGroups, params).then((res) => {
        if ([200, 201, 204, 202].includes(res.status)) {
          this.hostGroupList = res.data.results;
        }
      });
    },
  },
  mounted() {
    this.getHostGroups();
    this.getMaster();
  },
};
</script>
<style lang="less" scoped>
.form-content-host {
  max-height: 480px;
  height: 480px;
  overflow-y: auto;
}
/deep/.ant-modal-footer {
  display: none;
}
</style>
