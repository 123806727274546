<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2023-02-15 10:16:33
 * @FilePath: \awx-ui\src\pages\titleManage\components\runCommond.vue
-->
<template>
  <div style="padding: 10px 10px 10px 0">
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol" :form="form" class="p0-32-10-32 form-content-host">
      <!-- <a-form-item label="命令类型">
        <a-select placeholder="请选择命令类型" v-decorator="[
              'commondType',
              { rules: [{ required: true, message: '命令类型不能为空!' }] },
            ]">
          <a-select-option value="命令">命令</a-select-option>
          <a-select-option value="脚本">脚本</a-select-option>
        </a-select>
      </a-form-item>-->
      <!-- <a-form-item label="超时时间">
        <a-input v-decorator="[
              'timeout',
              { rules: [{ required: true, message: '超时时间不能为空!' }] },
            ]" placeholder="请输入超时时间" />
      </a-form-item>-->
      <a-form-item label="命令类型">
        <a-select placeholder="请选择命令类型" v-decorator="[
            'lang',
            { rules: [{ required: true, message: '命令类型不能为空!' }] },
            ]">
          <a-select-option v-for="item in tabsItem" :key="item.key" :value="item.key">{{item.label}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="名称">
        <a-auto-complete v-decorator="[
              'name',
              { rules: [{ required: true, message: '名称不能为空!' }]  },
            ]" :data-source="labelsList" placeholder="输入或选择名称" @change="changeName" @select="selectName" />
      </a-form-item>
      <a-form-item label="详细内容">
        <editor id="editor" ref="formEditor" :editorHeight="'200px'" :propsCode="logData" :readOnly="false" theme="vs-dark" language="language" @getEditorValue="getEditorValue" />
      </a-form-item>
    </a-form>
    <div class="ant-modal-confirm-btns-new" style="margin: 0">
      <a-button style="margin-right: 10px" type="primary" @click.stop="handleSubmit" :loading="loading">确认</a-button>
      <a-button @click.stop="formCancel">取消</a-button>
    </div>
  </div>
</template>
<script>
import editor from "@/components/editor";
import moment from "moment";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    editor,
  },
  props: {
    ids: {
      type: Array,
      default: function () {
        return [];
      },
    },
    callBack: Function,
  },
  data() {
    const self = this;
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
      currentTabsKey: "bash",
      tabsItem: [
        {
          label: "Bash",
          key: "bash",
        },
        {
          label: "Shell",
          key: "sh",
        },
        {
          label: "Python2",
          key: "python2",
        },
        {
          label: "Python3",
          key: "python3",
        },
        {
          label: "Ruby",
          key: "ruby",
        },
        {
          label: "Perl",
          key: "perl",
        },
        {
          label: "PowerShell",
          key: "powershell",
        },
      ],
      code: "",
      logData: "",
      form: this.$form.createForm(this, {
        onValuesChange: function (props, fileds) {},
      }),
      showAuthFlag: false,
      loading: false,
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 19, offset: 5 },
        },
      },
      labelsList: [],
      nameList: [],
      editAble: false,
    };
  },
  watch: {},
  methods: {
    changeName(val) {
      // 
      this.editAble = true;
      // const arr = this.nameList.filter((item) => item.name === val);
      // // 判断是选中还是输入
      // if (!arr.length) {
      //   this.editAble = true;
      // } else {
      //   this.editAble = false;
      // }
    },
    selectName(val) {
      const obj = this.nameList.filter((item) => item.name === val)[0];
      if (obj.type === "commond") this.logData = obj.code;
      if (obj.type === "script") {
        this.$axiosGet(`${global.API.downloadFile}${obj.fullpath}`)
          .then((res) => {
            if (typeof res.data === "string") {
              this.logData = res.data;
            } else {
              this.logData = "";
            }
          }).catch(() => {});
      }
    },
    getEditorValue(value) {
      this.code = value;
      if (this.editAble) return false;
      // const name = this.form.getFieldValue("name");
      // const arr = this.nameList.filter((item) => item.name === name);
      // if (!arr.length) {
      let name = "";
      if (value.length > 14) {
        name = value.substring(0, 14);
      } else {
        name = value;
      }
      this.form.getFieldsValue(["name"]);
      this.form.setFieldsValue({
        name: name,
      });
      // }
    },
    formCancel() {
      this.$destroyAll();
    },
    handleSubmit(e) {
      const _this = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        console.log(values);
        if (!err) {
          if (!this.code) {
            this.$message.error("请输入详细内容");
            return false;
          }
          this.loading = true;
          let params = {
            name: values.name,
            client: "local",
            tgt_type: "list",
            tgt: this.ids,
            model: {
              lang: values.lang,
              code: this.code,
              name: values.name,
              timeout: 10,
              args: [],
            },
          };
          this.$axiosJsonPost(
            global.API.syncNow + "?save2task=true&async=true",
            params
          ).then((res) => {
            this.loading = false;
            if ([200, 201, 204, 202].includes(res.status)) {
              this.$message.success("提交成功");
              _this.$destroyAll();
              _this.callBack();
            }
          });
        }
      });
    },
    getLabelList() {
      this.$axiosGet(global.API.getOrderList).then((res) => {
        if (res.data.results && res.data.results.length > 0) {
          res.data.results.map((item) => {
            this.labelsList.push(item.name);
            this.nameList.push({ ...item, type: "commond" });
          });
        }
      });
      this.$axiosGet(global.API.getScriptList).then((res) => {
        this.treeSpain = false;
        if (res.data && res.data.length > 0) {
          res.data.map((item) => {
            this.labelsList.push(item.name);
            this.nameList.push({ ...item, type: "script" });
          });
        }
      });
    },
    init() {
      this.form.getFieldsValue(["name", "lang"]);
      this.form.setFieldsValue({
        lang: "bash",
        name: "目标命令_" + moment(new Date()).format("YYYYMMDDHHmmss"),
      });
    },
  },
  mounted() {
    this.init();
    this.getLabelList();
  },
};
</script>
  <style lang="less" scoped>
.form-content-host {
  max-height: 580px;
  //   overflow-y: auto;
}
/deep/.ant-modal-footer {
  display: none;
}
</style>
  