<!--
 * @Author: mjzhu
 * @Date: 2022-06-08 11:38:30
 * @LastEditTime: 2024-01-24 14:29:49
 * @FilePath: \awx-ui\src\pages\hostManage\components\batchLabelModal.vue
-->
<template>
  <div style="padding: 10px 10px 10px 0">
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol" :form="form" class="p0-32-10-32 form-content-host">
      <a-form-item label="添加方式">
        <a-radio-group v-decorator="['addType', { rules: [{ required: true, message: '添加方式不能为空!' }]}]" placeholder="请选择添加方式">
          <a-radio :value="1">追加</a-radio>
          <a-radio :value="2">替换</a-radio>
        </a-radio-group>
      </a-form-item>
     
      <a-form-item label="主机标签">
        <a-select mode="tags" v-decorator="['labels', { rules: [{ required: true, message: '主机标签不能为空!' }]}]" placeholder="请输入你想添加的主机标签">
          <a-select-option :value="item" v-for="(item,index) in labelsList" :key="index">{{item}}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
    <div class="ant-modal-confirm-btns-new" style="margin: 0">
      <a-button style="margin-right: 10px" type="primary" @click.stop="handleSubmit" :loading="loading">确认</a-button>
      <a-button @click.stop="formCancel">取消</a-button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    ids: {
      type: Array,
      default: function () {
        return []
      },
    },
    clusterId: String,
    callBack: Function,
  },
  data() {
    const self = this
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 19 },
      },
      initFormFiledFlag: false,
      hostVals: [],
      form: this.$form.createForm(this, {
        onValuesChange: function (props, fileds) {},
      }),
      loading: false,
      hostGroupList: [],
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 19, offset: 5 },
        },
      },
      labelsList: [],
    };
  },
  watch: {},
  methods: {
    formCancel() {
      this.$destroyAll();
    },
    handleSubmit(e) {
      const _this = this;
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const params  = values.labels
          this.loading = true;
          const ajaxApi = global.API.getHostList + `/${this.ids.join(',')}/labels?cluster_id=${this.clusterId}`
          console.log(params, 'paramsparamsparams')
          if (values.addType === 1) {
            this.$axiosPatch(ajaxApi, params)
              .then((res) => {
                this.loading = false;
                if ([200, 201, 204, 202].includes(res.status)) {
                  this.$message.success("添加标签成功", 2);
                  this.$destroyAll();
                  _this.callBack();
                }
              })
              .catch((err) => {});
            return false;
          }
          this.$axiosPut(ajaxApi, params)
            .then((res) => {
              this.loading = false;
              if ([200, 201, 204, 202].includes(res.status)) {
                this.$message.success("替换标签成功", 2);
                this.$destroyAll();
                _this.callBack();
              }
            })
            .catch((err) => {});
        }
      });
    },
    init() {
      this.form.getFieldsValue(['labels', 'addType']);
      this.form.setFieldsValue({ 
        labels: undefined,
        addType: 1
      });
    },
  },
  mounted() {
    this.init()
  },
};
</script>
<style lang="less" scoped>
.form-content-host {
  max-height: 580px;
  overflow-y: auto;
}
</style>
